import _ from "lodash"
import "moment/locale/de"
import { styled } from "@mui/material/styles"
import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Collapse from "@mui/material/Collapse"
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import getCurrencyString from "../app/utils/getCurrencyString.js"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import moment from "moment"
import SellIcon from "@mui/icons-material/Sell"
import Typography from "@mui/material/Typography"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

const OrderCard = ({order, security, orderType, exchange, currency, modify, handleTransaction, deleteOrderFunc, openLookSecurity}) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const lookSecurityButton = (event) => {
    event.stopPropagation()
    openLookSecurity()
  }

  return (
    <Card sx={{ marginBottom: 2, backgroundColor: "rgb(240,240,240)" }}>
      <CardHeader
        action={
          <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={order.type + "sorder von " + security.name}
        subheader={"Verfallsdatum: " + moment(order.expirationDate).locale("de").format("D. MMMM YYYY")}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }} onClick={handleExpandClick}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Ordertyp:</b> {orderType}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Wertpapierbörse:</b> {exchange}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{marginTop:-2.5, marginBottom:-0.5}}>
                <Grid item sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Wertpapier:</b> {security.name != "" && security.name != null ? security.name : "Keine Wertpapier"}
                  </Typography>
                </Grid>
                {security.name != "" && security.name != null ?
                  <Grid item sx={{display: "flex", alignItems: "center"}}>
                    <IconButton sx={{backgroundColor: "rgb(204, 204, 204)"}} onClick={(event) => lookSecurityButton(event)}>
                      <ContentPasteSearchIcon  sx={{fontSize:"20px"}}/>
                    </IconButton>
                  </Grid>
                  : null
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Anzahl:</b> {order.number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Übrig:</b> {order.unfulfilled}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Kaufkurs:</b> {getCurrencyString(order.price, currency.code)}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Währung:</b> {currency == null ? null : currency.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Begründung:</b> {order.justification}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{marginTop: 1}}>
          <IconButton sx={{ backgroundColor: "rgb(200,200,200)" }} onClick={() => modify(order)}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ backgroundColor: "rgb(200,200,200)", marginRight: 1 }} onClick={() => deleteOrderFunc(order)} disabled={order.numberDependencies>0}>
            <DeleteIcon />
          </IconButton>
          <IconButton sx={{ backgroundColor: "rgb(200,200,200)"}} onClick={() => handleTransaction(order)} disabled={order.unfulfilled<=0}>
            <SellIcon />
          </IconButton>
          <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: "14px", textAlign: "center", color : order.unfulfilled<=0 ? "rgb(153,153,153)" : "rgb(51,51,51)" }}>
              Transaktion<br/>erfassen
          </Typography>
        </CardActions>
      </Collapse>
    </Card>
  )
}

export default OrderCard
