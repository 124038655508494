import _ from "lodash"
import "moment/locale/de"
import { DialogContent } from "@mui/material"
import api from "../app/api.js"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import PdfPage from "./PdfPage"
import React from "react"
//import { Document, Page } from '@react-pdf/renderer'

const LookDocument = ({onClose, open, transaction}) => {
  const { data: file, isLoadingFile } = api.useGetFileQuery(transaction.id)

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
    <DialogTitle>Dokument</DialogTitle>
    <DialogContent>
      {
        isLoadingFile ? null : "Datei-Anzeige in Arbeit"
        /*<Document file={file}>
          <Page pageNumber={1} />
        </Document>*/
      }
    </DialogContent>
    </Dialog>
  )
}

export default LookDocument
