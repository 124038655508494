import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import Depot from "../../pages/Depot"
import Order from "../../pages/Order"
import React from "react"
import ReceiptIcon from "@mui/icons-material/Receipt"
import Result from "../../pages/Result"
import Security from "../../pages/Security"
import Settings from "../../pages/Settings"
import SettingsIcon from "@mui/icons-material/Settings"
import ShowChartIcon from "@mui/icons-material/ShowChart"
import Transaction from "../../pages/Transaction"
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle'

const AppMenuLinks = () => {
  const links = [
    { href: "/depot", text: "Depots", icon: <AccountBalanceIcon/>, component: <Depot/> },
    { href: "/wertpapier", text: "Wertpapiere", icon: <ReceiptIcon/>, component: <Security/> },
    { href: "/order", text: "Orders", icon: <PlaylistAddCheckCircleIcon/>, component: <Order/> },
    { href: "/transaktion", text: "Transaktionen", icon: <CurrencyExchangeIcon/>, component: <Transaction/> },
    { href: "/auswertungen", text: "Auswertungen", icon: <ShowChartIcon/>, component: <Result/> },
    { href: "/einstellungen", text: "Einstellungen", icon: <SettingsIcon/>, component: <Settings/> }
  ]
  return links
}

export default AppMenuLinks
