import _ from "lodash"
import "moment/locale/de"
import { styled } from "@mui/material/styles"
import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Collapse from "@mui/material/Collapse"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import moment from "moment"
import Typography from "@mui/material/Typography"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  })
}))

const DepotCard = ({data, currency, modify, deleteDepotFunc}) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card sx={{backgroundColor: "rgb(240,240,240)"}}>
      <CardHeader
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={data.bank + " " + data.accountNumber + " (" + data.name + " " + data.surname + ")"}
        subheader={"Erstellt am " + moment(data.creationDate).locale("de").format("D. MMMM YYYY, h:mm:ss")}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{paddingTop: 0, paddingBottom: 0}} onClick={handleExpandClick}>
          <Grid container spacing={2}>
            <Grid container spacing={1} item xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Name der Bank:</b> {data.bank}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Depotnummer:</b> {data.accountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Währung:</b> {currency}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{marginBottom: 1}}>
                Depotinhaber
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Anrede:</b> {data.salutation}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Vorname:</b> {data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Name:</b> {data.surname}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Straße HNR:</b> {data.street}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>PLZ:</b> {data.postalCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Ort:</b> {data.city}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{marginTop: 1}}>
          <IconButton sx={{backgroundColor: "rgb(200,200,200)"}} onClick={() => modify(data)}>
            <EditIcon/>
          </IconButton>
          <IconButton sx={{backgroundColor: "rgb(200,200,200)"}} onClick={() => deleteDepotFunc(data)} disabled={data.numberDependencies>0}>
            <DeleteIcon/>
          </IconButton>
        </CardActions>
      </Collapse>
    </Card>
  )
}

export default DepotCard
