import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { sliceActions } from "./slice"
import AppRestUrls from "./constants/AppRestUrls"

const tagTypes = [
  "me", "user", "depot", "security", "stockOrder", "transaction",
  "currency",
  "securityCategory", "investmentCategory", "informationSource", "orderType", "strategy", "exchange",
  "file"
]

const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: AppRestUrls.base(),
    prepareHeaders: (headers) => {
      headers.set("X-UNAUTH-BEHAVIOUR", "return-401")
      return headers
    },
    credentials: "include"
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getMe: build.query({
      query: () => "user/me",
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
       try {
          const res = await queryFulfilled
          dispatch(sliceActions.setMe(res.data))
        } catch (e) {
          //error handled in AuthPage
        }
      },
      providesTags: ["me"]
    }),

    getUser: build.query({ query: () => "user", providesTags: ["user"]}),
    postUser: build.mutation({
      query: args => ({ url: "user", method: "POST", body: args }),
      invalidatesTags: ["user"]
    }),
    putUser: build.mutation({
      query: args => ({ url: `user/${args.username}`, method: "PUT", body: args }),
      invalidatesTags: ["user"]
    }),
    deactivateUser: build.mutation({
      query: args => ({ url: `user/deactivate/${args.username}`, method: "PUT" }),
      invalidatesTags: ["user"]
    }),

    putPassword: build.mutation({
      query: args => ({ url: "user/password", method: "PUT", body: args })
    }),
    getNewHelpdeskMessages: build.query({
      query: () => "user/me/support/new-messages",
      providesTags: ["newHelpdeskMessages"]
    }),

    getDepot: build.query({ query: () => "depot", providesTags: ["depot"]}),
    postDepot: build.mutation({
      query: args => ({ url: "depot", method: "POST", body: args }),
      invalidatesTags: ["depot", "me"]
    }),
    deleteDepot: build.mutation({
      query: args => ({ url: `depot/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["depot", "me"]
    }),

    getSecurity: build.query({ query: () => "security", providesTags: ["security"]}),
    postSecurity: build.mutation({
      query: args => ({ url: "security", method: "POST", body: args }),
      invalidatesTags: ["security", "me"]
    }),
    deleteSecurity: build.mutation({
      query: args => ({ url: `security/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["security", "me"]
    }),

    getOrder: build.query({ query: () => "stockOrder", providesTags: ["stockOrder"]}),
    postOrder: build.mutation({
      query: args => ({ url: "stockOrder", method: "POST", body: args }),
      invalidatesTags: ["stockOrder", "me"]
    }),
    deleteOrder: build.mutation({
      query: args => ({ url: `stockOrder/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["stockOrder", "me"]
    }),

    getTransaction: build.query({ query: () => "transaction", providesTags: ["transaction"]}),
    postTransaction: build.mutation({
      query: args => ({ url: "transaction", method: "POST", body: args }),
      invalidatesTags: ["transaction", "me"]
    }),
    deleteTransaction: build.mutation({
      query: args => ({ url: `transaction/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["transaction", "me"]
    }),

    getFile: build.query({ query: (id) => "transaction/"+id+"/file", providesTags: ["file"]}),

    getSimpleChoice: build.query({ query: () => "simpleChoice", providesTags: ["simpleChoice"]}),
    postSimpleChoice: build.mutation({
      query: args => ({ url: "simpleChoice", method: "POST", body: args }),
      invalidatesTags: ["simpleChoice", "me"]
    }),
    deleteSimpleChoice: build.mutation({
      query: args => ({ url: `simpleChoice/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["simpleChoice", "me"]
    }),

    getCurrency: build.query({ query: () => "currency", providesTags: ["currency"]}),
    postCurrency: build.mutation({
      query: args => ({ url: "currency", method: "POST", body: args }),
      invalidatesTags: ["currency", "me"]
    }),
    deleteCurrency: build.mutation({
      query: args => ({ url: `currency/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["currency", "me"]
    }),

    getExitStrategy: build.query({ query: () => "exitStrategy", providesTags: ["exitStrategy"]}),
    postExitStrategy: build.mutation({
      query: args => ({ url: "exitStrategy", method: "POST", body: args }),
      invalidatesTags: ["exitStrategy", "me"]
    }),
    deleteExitStrategy: build.mutation({
      query: args => ({ url: `exitStrategy/${args}`, method: "DELETE", body: args }),
      invalidatesTags: ["exitStrategy", "me"]
    })
  })
})

export default api
export { tagTypes }
