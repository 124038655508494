import { sliceActions } from "../app/slice"
import { tagTypes } from "../app/api"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import api from "../app/api"
import AppRestUrls from "../app/constants/AppRestUrls"

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const logout = async () => {
      localStorage.clear()
      dispatch(sliceActions.logout())
      dispatch(api.util.invalidateTags(tagTypes))

      window.open(AppRestUrls.logout(), "_self")
    }

    logout()
  })

  return null
}

export default Logout
