import _ from "lodash"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

const AddCurrency = ({currency, onSave, handleChange, onClose, open}) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{currency.id=="" ? "Wahrung aufzeichnen" : "Wahrung ändern"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} sx={{paddingLeft: 2, paddingTop: 2}}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Code"
              variant="outlined"
              value={currency.code}
              onChange={(event) => handleChange({ code: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={currency.name}
              onChange={(event) => handleChange({ name: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Symbole"
              variant="outlined"
              value={currency.symbol}
              onChange={(event) => handleChange({ symbol: event.target.value })}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={onSave}
        >
          Speichern
        </Button>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 4}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default AddCurrency
