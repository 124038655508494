import { useEffect } from "react"
import AppRestUrls from "../app/constants/AppRestUrls"

const Login = () => {
  useEffect(() => {
    localStorage.clear()

    window.open(AppRestUrls.login(), "_self")
  })

  return null
}

export default Login
