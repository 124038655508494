import _ from "lodash"
import "moment/locale/de"
import { styled } from "@mui/material/styles"
import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Collapse from "@mui/material/Collapse"
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import moment from "moment"
import OutputIcon from "@mui/icons-material/Output"
import Typography from "@mui/material/Typography"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

const SecurityCard = ({security, securityCategory, investmentCategory, informationSource, depot, openLookDepot, openHandleExitStrategy, modify, deleteSecurityFunc}) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const lookDepotButton = (event) => {
    event.stopPropagation()
    openLookDepot(depot)
  }
  const handleExitStrategy = (event) => {
    event.stopPropagation()
    openHandleExitStrategy(security)
  }

  return (
    <Card sx={{marginBottom: 2, backgroundColor: "rgb(240,240,240)"}}>
      <CardHeader
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={security.name}
        subheader={"Erstellt am " + moment(security.creationDate).locale("de").format("D. MMMM YYYY, h:mm:ss")}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{paddingTop: 0, paddingBottom: 0}} onClick={handleExpandClick}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>WKN / ISIN / US-Kürzel:</b> {security.code}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Wertpapierkategorie:</b> {securityCategory}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Investmentkategorie:</b> {investmentCategory}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Empfehlungsquelle:</b> {informationSource}
              </Typography>
              </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Notizen:</b> {security.notizen}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{marginTop:-2.75}}>
                <Grid item sx={{display: "flex", alignItems: "center"}}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    <b>Depot:</b> {depot.bank + " " + depot.accountNumber + (depot.accountNumber != null ? "" : "Kein Depot")}
                  </Typography>
                </Grid>
                {depot.accountNumber != "" ?
                  <Grid item sx={{display: "flex", alignItems: "center"}}>
                    <IconButton sx={{backgroundColor: "rgb(204, 204, 204)"}} onClick={(event) => lookDepotButton(event)}>
                      <ContentPasteSearchIcon/>
                    </IconButton>
                  </Grid>
                  : null
                }
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Verkaufsstrategie:</b>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{marginTop: 1}}>
          <IconButton sx={{backgroundColor: "rgb(200,200,200)"}} onClick={() => modify(security)}>
            <EditIcon/>
          </IconButton>
          <IconButton sx={{backgroundColor: "rgb(200,200,200)", marginRight: 1 }} onClick={() => deleteSecurityFunc(security)} disabled={security.numberDependencies>0}>
            <DeleteIcon/>
          </IconButton>
          <IconButton sx={{backgroundColor: "rgb(200,200,200)"}} onClick={handleExitStrategy}>
            <OutputIcon/>
          </IconButton>
          <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: "14px", textAlign: "center"}}>
            Verkaufsstrategie<br/>erfassen
          </Typography>
        </CardActions>
      </Collapse>
    </Card>
  )
}

export default SecurityCard