import { Dialog, DialogTitle } from "@mui/material"
import { Navigate } from "react-router-dom"
import api from "../api"
import React from "react"

const AuthPage = ({children}) => {
  const { data: me, error, isLoading } = api.useGetMeQuery()

  return (isLoading
    ? null
    : !!me
      ? children
      : error.status === 401
        ? <Navigate to="/login" replace/>
        : <Dialog open={true}>
            <DialogTitle>Unknown Error: {JSON.stringify(error)}</DialogTitle>
          </Dialog>
  )
}

export default AuthPage
