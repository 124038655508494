import _ from "lodash"
import "moment/locale/de"
import * as React from "react"
import AddIcon from "@mui/icons-material/Add"
import api from "../app/api.js"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import DepotCard from "../depot/DepotCard.js"
import EmptyTemplates from "../app/constants/EmptyTemplates.js"
import FormControlLabel from "@mui/material/FormControlLabel"
import HandleDepot from "../depot/HandleDepot.js"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const Depot = () => {

  const [handleDepot, setHandleDepot] = React.useState(false)
  const [targetDepot, setTargetDepot] = React.useState(EmptyTemplates.emptyDepot)

  const { data: depots, isLoadingDepots } = api.useGetDepotQuery()
  const [postDepot, postDepotResult] = api.usePostDepotMutation()
  const [deleteDepot, deleteDepotResult] = api.useDeleteDepotMutation()

  const { data: currencies, isLoadingCurrencies } = api.useGetCurrencyQuery()

  const [filterTerm, setFilterTerm] = React.useState("")
  const [strictSearch, setStrictSearch] = React.useState(false)

  const stillLoading = () => {
    return isLoadingDepots || isLoadingCurrencies
  }

  const filterFunc = (element) => {
    if(filterTerm == ""){
      return true
    }
    let remains = false
    _.map(_.entries(element), word => {
      if(remains==false && word[0]!="id"){
        remains = strictSearch ? (word[1].toString().toLowerCase()).substring(0,filterTerm.length)==filterTerm.toLowerCase() : (word[1].toString().toLowerCase()).includes(filterTerm.toLowerCase())
      }
    })
    return remains
  }

  const saveDepot = async () => {
    if(targetDepot.bank!="" && targetDepot.accountNumber!="" && targetDepot.salutation!="" && targetDepot.name!="" && targetDepot.surname!="" && targetDepot.street!="" && targetDepot.postalCode!="" && targetDepot.city!=""){
      setHandleDepot(false)
      try{
        const res = await postDepot(targetDepot)
      }catch{
      }
    }
  }
  const changeDepot = (newValue) => {
    setTargetDepot({...targetDepot, ...newValue})
  }
  const openHandleDepot = (target) => {
    setTargetDepot(target)
    setHandleDepot(true)
  }
  const closeHandleDepot = () => {
    setHandleDepot(false)
  }
  const deleteDepotFunc = async (target) => {
    try{
      const res = await deleteDepot(target.id)
    }catch{
    }
  }

  return (
    <Box>
      <Typography align="center" variant="body1" sx={{ fontSize: "30px", paddingBottom: 1}}>
        Depots
      </Typography>
      <Container maxWidth="1000px" fixed>
        <Box>
          <TextField
            id="outlined-basic"
            label="Suche..."
            variant="outlined"
            onChange={(event) =>
            setFilterTerm(event.target.value)}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Strenge Suche"
            sx={{paddingTop: 1, paddingLeft: 2}}
            checked={strictSearch}
            onChange={() => setStrictSearch(!strictSearch)}
            />
          <Box sx={{height: "68vh", paddingLeft: 1, paddingTop: 3, overflow: "auto"}}>
            <Grid container spacing={2}>
              {stillLoading() ? null : _.map(_.filter(depots, (element) => filterFunc(element)), depot =>{
              const currency = _.find(currencies, (element) => { return element.id==depot.currencyId})
                return (
                  <Grid item xs={12} lg={6}>
                      <DepotCard
                        data={depot}
                        currency={currency == null ? null : currency.name}
                        modify={value => openHandleDepot(value)}
                        deleteDepotFunc={value => deleteDepotFunc(value)}
                      />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Button
            variant="contained"
            sx={{ height: 40, width: 40, minWidth: 40, float: "right"}}
            onClick={() => openHandleDepot(EmptyTemplates.emptyDepot)}
          >
            <AddIcon/>
          </Button>
        </Box>
        <HandleDepot
          open={handleDepot}
          onClose={closeHandleDepot}
          onSave={saveDepot}
          changeDepot={changeDepot}
          depot={targetDepot}
          currencies={currencies}
        />
      </Container>
    </Box>
  )
}

export default Depot
