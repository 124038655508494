import _ from "lodash"
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom"
import AppMenuLinks from "./app/constants/AppMenuLinks.js"
import AuthPage from "./app/components/AuthPage.js"
import Login from "./pages/Login"
import LoginError from "./pages/LoginError"
import Logout from "./pages/Logout"
import PasswordEdit from "./pages/PasswordEdit"
import Page from "./app/components/Page.js"
import React from "react"

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/depot" replace/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path='/login-error' element={<LoginError/>}/>
        <Route path='/logout' element={<Logout/>}/>
        <Route path='/passwort' element={<PasswordEdit/>}/>
        {_.map(AppMenuLinks(), (link) => {
          return (
            <Route path={link.href} element={<AuthPage><Page>{link.component}</Page></AuthPage>}/>
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}

export default App
