import fetch from "node-fetch"

export default async (url, ctx = {}) => {
  const headers = {
    "Accept": ctx && ctx.req && ctx.req.headers ? ctx.req.headers.accept : ""
  }

  const response = await fetch(url, {
    headers: headers,
  })
  return await response.json()
}
