import _ from "lodash"
import { Dialog } from "common_components"
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined"

const ErrorDialog = ({ close, errors, ok, open }) => {
  return (
    <Dialog
      close={close}
      open={open}
      text={
        <List>
          {_.map(errors, error => (
            <ListItem key={error}>
              <ListItemIcon>
                <ReportProblemOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={error}
                sx={{ overflowWrap: "break-word" }}
              />
            </ListItem>
          ))}
        </List>
      }
      ok={ok}
    />
  )
}

export default ErrorDialog
