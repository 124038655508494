
import _ from "lodash"
import "moment/locale/de"
import * as React from "react"
import AddIcon from "@mui/icons-material/Add"
import api from "../app/api.js"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import EmptyTemplates from "../app/constants/EmptyTemplates.js"
import FormControlLabel from "@mui/material/FormControlLabel"
import HandleOrder from "../order/HandleOrder.js"
import HandleTransaction from "../order/HandleTransaction.js"
import LookSecurity from "../order/LookSecurity.js"
import OrderCard from "../order/OrderCard.js"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { autoBatchEnhancer } from "@reduxjs/toolkit"

const Order = () => {

  const [handleOrder, setHandleOrder] = React.useState(false)
  const [targetOrder, setTargetOrder] = React.useState(EmptyTemplates.emptyOrder)

  const [handleTransaction, setHandleTransaction] = React.useState(false)
  const [targetTransaction, setTargetTransaction] = React.useState(EmptyTemplates.emptyTransaction)

  const [lookSecurity, setLookSecurity] = React.useState(false)
  const [targetSecurity, setTargetSecurity] = React.useState(EmptyTemplates.emptySecurity)

  const { data: securities, isLoadingSecurities } = api.useGetSecurityQuery()

  const { data: orders, isLoadingOrders } = api.useGetOrderQuery()
  const [postOrder, postOrderResult] = api.usePostOrderMutation()
  const [deleteOrder, deleteOrderResult] = api.useDeleteOrderMutation()

  const { data: transactions, isLoadingTransactions } = api.useGetTransactionQuery()
  const [postTransaction, postTransactionResult] = api.usePostTransactionMutation()

  const { data: simpleChoices, isLoadingSimpleChoices } = api.useGetSimpleChoiceQuery()
  const { data: currencies, isLoadingCurrencies } = api.useGetCurrencyQuery()

  const [filterTerm, setFilterTerm] = React.useState("")
  const [strictSearch, setStrictSearch] = React.useState(false)
  const [hideFullfilled, setHideFullfilled] = React.useState(false)

  const stillLoading = () => {
    return isLoadingSecurities || isLoadingOrders || isLoadingTransactions || isLoadingSimpleChoices || isLoadingCurrencies
  }

  const filterFunc = (element) => {
    if(element.unfulfilled <= 0 && hideFullfilled){
      return false
    }
    if(filterTerm == ""){
      return true
    }
    let remains = false
    _.map(_.entries(element), word => {
      if(remains==false && word[0]!="id" && word[1]!=null){
        if(word[0]=="securityId"){
          const securityWord = _.find(securities, (element) => { return element.id==word[1]}).name
          remains = strictSearch ? (securityWord.toString().toLowerCase()).substring(0,filterTerm.length)==filterTerm.toLowerCase() : (securityWord.toString().toLowerCase()).includes(filterTerm.toLowerCase())
        }else{
          remains = strictSearch ? (word[1].toString().toLowerCase()).substring(0,filterTerm.length)==filterTerm.toLowerCase() : (word[1].toString().toLowerCase()).includes(filterTerm.toLowerCase())
        }
      }
    })
    return remains
  }

  const saveOrder = async () => {
    if(
      targetOrder.securityId!="" &&
      targetOrder.type!="" &&
      targetOrder.orderTypeId!="" &&
      targetOrder.exchangeId!="" &&
      targetOrder.number!="" &&
      targetOrder.price!="" &&
      targetOrder.currencyId!="" &&
      targetOrder.expirationDate!=""
    ){
      setHandleOrder(false)
      try{
        await postOrder(targetOrder)
      }catch{
      }
    }
  }
  const changeOrder = (newValue) => {
    setTargetOrder({...targetOrder, ...newValue})
  }
  const deleteOrderFunc = async (target) => {
    try{
      await deleteOrder(target.id)
    }catch{
    }
  }

  const openHandleOrder = (target) => {
    setTargetOrder(target)
    setHandleOrder(true)
  }
  const closeHandleOrder = () => {
    setHandleOrder(false)
    setTargetOrder(EmptyTemplates.emptyOrder)
  }

  const saveTransaction = async () => {
    if(
      targetTransaction.stockOrderId!="" &&
      targetTransaction.type!="" &&
      targetTransaction.transactionDate!="" &&
      targetTransaction.number!="" &&
      targetTransaction.price!="" &&
      targetTransaction.currencyId!="" &&
      targetTransaction.fees!=""
    ){
      setHandleTransaction(false)
      try{
        const formData = new FormData()
        _.forOwn(targetTransaction, (value, key) => formData.set(key, value))
        await postTransaction(formData)
      }catch{
      }
    }
  }
  const changeTransaction = (newValue) => {
    setTargetTransaction({...targetTransaction, ...newValue})
  }

  const openHandleTransaction = (target) => {
    setTargetTransaction({...EmptyTemplates.emptyTransaction, stockOrderId:target.id, type:target.type, number:target.number, price:target.price, currencyId:target.currencyId})
    setTargetOrder(target)
    setHandleTransaction(true)
  }
  const closeHandleTransaction = () => {
    setHandleTransaction(false)
    setTargetTransaction(EmptyTemplates.emptyTransaction)
  }

  const openLookSecurity = (target) => {
    setTargetSecurity(target)
    setLookSecurity(true)
  }
  const closeLookSecurity = () => {
    setLookSecurity(false)
  }

  return (
    <Box>
      <Typography align="center" variant="body1" sx={{ fontSize: "30px", paddingBottom: 1}}>
        Orders
      </Typography>
      <Container maxWidth="1000px" fixed>
        <Box>
          <TextField
            id="outlined-basic"
            label="Suche..."
            variant="outlined"
            onChange={(event) =>
            setFilterTerm(event.target.value)}
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="Strenge Suche"
            sx={{paddingTop: 1, paddingLeft: 2}}
            checked={strictSearch}
            onChange={() => setStrictSearch(!strictSearch)}
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="Erfüllte Orders ausblenden"
            sx={{paddingTop: 1, paddingLeft: 2}}
            checked={hideFullfilled}
            onChange={() => setHideFullfilled(!hideFullfilled)}
          />
          <Box sx={{height: "68vh", paddingLeft: 1, paddingTop: 3, overflow: "auto"}}>
            <Grid container spacing={2}>
              {stillLoading() ? null : _.map(_.filter(orders, (element) => filterFunc(element)), order =>{
              const security = _.find(securities, (element) => { return element.id==order.securityId})
              const orderType = _.find(simpleChoices, (element) => { return element.id==order.orderTypeId})
              const exchange = _.find(simpleChoices, (element) => { return element.id==order.exchangeId})
              const currency = _.find(currencies, (element) => { return element.id==order.currencyId})
              return (
                <Grid item xs={12} lg={6}>
                  <OrderCard
                    order={order}
                    security={security == null ? EmptyTemplates.emptySecurity : security}
                    orderType={orderType == null ? null : orderType.name}
                    exchange={exchange == null ? null : exchange.name}
                    currency={currency == null ? EmptyTemplates.emptyCurrency : currency}
                    modify={value => openHandleOrder(value)}
                    handleTransaction={value => openHandleTransaction(value)}
                    openLookSecurity={() => openLookSecurity(security)}
                    deleteOrderFunc={value => deleteOrderFunc(value)}
                  />
                </Grid>
              )
              })}
            </Grid>
          </Box>
          <Button
            variant="contained"
            sx={{ height: 40, width: 40, minWidth: 40, float: "right"}}
            onClick={() => openHandleOrder(EmptyTemplates.emptyOrder)}
          >
            <AddIcon/>
          </Button>
        </Box>
        <HandleOrder
          open={handleOrder}
          onClose={closeHandleOrder}
          onSave={saveOrder}
          changeOrder={changeOrder}
          order={targetOrder}
          securities={securities}
          orderTypes={_.filter(simpleChoices, (choice) => { return choice.category == "Order Type" })}
          exchanges={_.filter(simpleChoices, (choice) => { return choice.category == "Exchange" })}
          currencies={currencies}
        />
        <HandleTransaction
          open={handleTransaction}
          onClose={closeHandleTransaction}
          onSave={saveTransaction}
          changeTransaction={changeTransaction}
          transaction={targetTransaction}
          orders={orders}
          securities={securities}
          order={targetOrder}
          currencies={currencies}
        />
        <LookSecurity
          open={lookSecurity}
          onClose={closeLookSecurity}
          security={targetSecurity}
        />
      </Container>
    </Box>
  )
}

export default Order
