import _ from "lodash"
import "moment/locale/de"
import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

const SimpleChoiceFilter = ({ label, array, setFilter }) => {
  return (
    <Grid item xs={12} md={4}>
      <Autocomplete
        id="combo-box-demo"
        onChange={(event, value, reason) => setFilter(_.find(array, (element) => { return element.name==value}))}
        options={_.map(array, "name")}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Grid>
  )
}

export default SimpleChoiceFilter
