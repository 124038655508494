import _ from "lodash"
import "moment/locale/de"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useEffect } from "react"
import * as React from "react"
import api from "../app/api.js"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import exchangeRateGet from "../result/exchangeRateGet.js"
import FormControl from "@mui/material/FormControl"
import getCurrencyString from "../app/utils/getCurrencyString.js"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import moment from "moment"
import Select from "@mui/material/Select"
import SimpleChoiceFilter from "../result/SimpleChoiceFilter.js"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

const Result = () => {

  const [filterDepot, setFilterDepot] = React.useState(null)
  const [filterSecurity, setFilterSecurity] = React.useState(null)

  const [filterSecurityCategory, setFilterSecurityCategory] = React.useState(null)
  const [filterInvestmentCategory, setFilterInvestmentCategory] = React.useState(null)
  const [filterStrategy, setFilterStrategy] = React.useState(null)
  const [filterInformationSource, setFilterInformationSource] = React.useState(null)
  const [filterOrderType, setFilterOrderType] = React.useState(null)
  const [filterExchange, setFilterExchange] = React.useState(null)

  const categories = [
    {singularTitle: "Wertpapierkategorie", pluralTitle:"Wertpapierkategorien", category:"Security Category", function:setFilterSecurityCategory},
    {singularTitle: "Investmentkategorie", pluralTitle:"Investmentkategorien", category:"Investment Category", function:setFilterInvestmentCategory},
    {singularTitle: "Strategie", pluralTitle:"Strategien", category:"Strategy", function:setFilterStrategy},
    {singularTitle: "Empfehlungsquelle", pluralTitle:"Empfehlungsquellen", category:"Information Source", function:setFilterInformationSource},
    {singularTitle: "Ordertyp", pluralTitle:"Ordertypen", category:"Order Type", function:setFilterOrderType},
    {singularTitle: "Wertpapierbörse", pluralTitle:"Wertpapierbörsen", category:"Exchange", function:setFilterExchange}
  ]

  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)

  const { data: depots, isLoadingDepots } = api.useGetDepotQuery()
  const { data: securities, isLoadingSecurities } = api.useGetSecurityQuery()
  const { data: orders, isLoadingOrders } = api.useGetOrderQuery()
  const { data: transactions, isLoadingTransactions } = api.useGetTransactionQuery()

  const { data: simpleChoices, isLoadingSimpleChoices } = api.useGetSimpleChoiceQuery()
  const { data: currencies, isLoadingCurrencies } = api.useGetCurrencyQuery()

  const [buyTotal, setBuyTotal] = React.useState(0)
  const [sellTotal, setSellTotal] = React.useState(0)

  useEffect(() => {
    const setScore = async (type) => {
      const exchangeRates = await exchangeRateGet("EUR")
      const filteredTransactions = _.filter(transactions, (element) => {
        const tOrder = _.find(orders, (order) => { return order.id==element.stockOrderId})
        const tSecurity = _.find(securities, (security) => { return security.id==tOrder.securityId})
        const tDepot = _.find(depots, (depot) => { return tSecurity == null ? false : depot.id==tSecurity.depotId })
        return (
          (filterDepot == null || tDepot.bank == filterDepot.bank) &&
          (filterSecurity == null || tSecurity.name == filterSecurity.name) &&
          (filterSecurityCategory == null || tSecurity.securityCategoryId == filterSecurityCategory.id) &&
          (filterInvestmentCategory == null || tSecurity.investmentCategoryId == filterInvestmentCategory.id) &&
          (filterInformationSource == null || tSecurity.informationSourceId == filterInformationSource.id) &&
          (startDate == null || moment(startDate).isBefore(element.transactionDate)) &&
          (endDate == null || moment(endDate).isAfter(element.transactionDate)) &&
          element.type == type
        )

      })
      let sum = 0
      filteredTransactions.forEach(element => {
        sum = sum + (element.price * element.number - (type == "Kauf" ? -element.fees : element.fees)) /
        exchangeRates.conversion_rates[_.find(currencies, (currency) => { return currency.id == element.currencyId }).code]
      })
      if(type=="Kauf"){
        setBuyTotal(sum)
      }else{
        setSellTotal(sum)
      }
    }
    setScore("Kauf")
    setScore("Verkauf")
  }, [filterDepot, filterSecurity, filterSecurityCategory, filterInvestmentCategory, filterStrategy, filterInformationSource, filterOrderType, filterExchange])

  const getTotal = () => {
    return sellTotal-buyTotal
  }

  return (
    <Box>
      <Typography align="center" variant="body1" sx={{ fontSize: "30px", paddingBottom: 1}}>
        Auswertungen
      </Typography>
      <Container maxWidth="1000px" fixed>
        <Box sx={{padding: 2}}>
          <Grid container rowSpacing={2} columnSpacing={2} sx={{padding:2}}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Depot</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterDepot == null ? null : filterDepot.id}
                  onChange={(event) => setFilterDepot(_.find(depots, (depot) => { return depot.id==event.target.value}))}
                  label="Depot"
                >
                  {_.map(depots, element => {
                    return(
                      <MenuItem value={element.id}>
                        {element.bank + " " + element.accountNumber}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                id="combo-box-demo"
                onChange={(event, value, reason) => setFilterSecurity(_.find(securities, (security) => { return security.name==value}))}
                options={_.map(securities, "name")}
                renderInput={(params) => <TextField {...params} label="Wertpapier" />}
              />
            </Grid>
            {_.map(categories, (element) => {
              return(
                <SimpleChoiceFilter
                  label={element.singularTitle}
                  array={_.filter(simpleChoices, (choice) => { return choice.category == element.category })}
                  setFilter={element.function}
                />
              )
            })}
          </Grid>

          <Grid container rowSpacing={2} columnSpacing={2} sx={{padding:2}}>
            <Grid item xs={12} md={3}>
              <DatePicker onChange={value => setStartDate(value)} label="Von Datum" />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker onChange={value => setEndDate(value)} label="Bis Datum" />
            </Grid>
          </Grid>

          <Box height="40vh" overflow="auto" sx={{marginBottom: 2}}>
            <Grid container spacing={2} sx={{padding: 2}}>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "24px" }}>
                  Gewinn: {getCurrencyString(sellTotal, "EUR")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "24px" }}>
                  Verlust: {getCurrencyString(buyTotal, "EUR")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "24px" }}>
                  Nettoergebnis: {getCurrencyString(getTotal(), "EUR")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Result
