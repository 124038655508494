import _ from "lodash"
import "moment/locale/de"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

const HandleSecurity = ({ open, onClose, onSave, changeSecurity, security, securityCategories, investmentCategories, informationSources, depots }) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{security.id=="" ? "Wertpapier aufzeichnen" : "Wertpapier ändern"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{paddingLeft: 2, paddingTop: 3}}>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="WKN / ISIN /US-Kürzel"
              variant="outlined"
              sx={{marginBottom: 2}}
              value={security.code}
              onChange={(event) => changeSecurity({ code : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Bezeichnung"
              variant="outlined"
              sx={{marginBottom: 2}}
              value={security.name}
              onChange={(event) => changeSecurity({ name : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={security.securityCategoryId == "" || security.securityCategoryId == null ? null : _.find(securityCategories, (element) => { return element.id==security.securityCategoryId}).name}
              onChange={(event, value, reason) => changeSecurity({ securityCategoryId : (value != null ? _.find(securityCategories, (element) => { return element.name == value}).id : null)  })}
              options={_.map(securityCategories, "name")}
              renderInput={(params) => <TextField {...params} label="Wertpapierkategorie"/> }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={security.investmentCategoryId == "" || security.investmentCategoryId == null ? null : _.find(investmentCategories, (element) => { return element.id==security.investmentCategoryId}).name}
              onChange={(event, value, reason) => changeSecurity({ investmentCategoryId : (value != null ? _.find(investmentCategories, (element) => { return element.name == value}).id : null)  })}
              options={_.map(investmentCategories, "name")}
              renderInput={(params) => <TextField {...params} label="Investmentkategorie" />}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={security.informationSourceId == "" || security.informationSourceId == null ? null : _.find(informationSources, (element) => { return element.id==security.informationSourceId}).name}
              onChange={(event, value, reason) => changeSecurity({ informationSourceId : (value != null ? _.find(informationSources, (element) => { return element.name == value}).id : null)  })}
              options={_.map(informationSources, "name")}
              renderInput={(params) => <TextField {...params} label="Empfehlungsquelle" />}
            />
            </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Notizen"
              variant="outlined"
              sx={{marginBottom: 2}}
              value={security.notizen}
              onChange={(event) => changeSecurity({ notizen : event.target.value})}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl required fullWidth>
              <InputLabel id="demo-simple-select-label">Depot</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={security.depotId}
                  onChange={(event) => changeSecurity({ depotId : event.target.value})}
                  label="Depot"
                >
                  {_.map(depots, depot => {
                    return (
                      <MenuItem value={depot.id}>
                        <Tooltip title={depot.bank + " " + depot.accountNumber}>
                          <div style={{width: "100%"}}>
                            <Typography sx={{marginRight: 1}}>
                              {depot.bank}
                            </Typography>
                          </div>
                        </Tooltip>
                      </MenuItem>
                    )
                  })}
                </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={onSave}
          disabled={
            security.code=="" ||
            security.name=="" ||
            security.depotId==""
          }
        >
          Speichern
        </Button>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 4}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default HandleSecurity
