import _ from "lodash"
import * as React from "react"
import AddCurrency from "../settings/AddCurrency.js"
import AddIcon from "@mui/icons-material/Add"
import AddSimpleChoice from "../settings/AddSimpleChoice.js"
import api from "../app/api.js"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import EmptyTemplates from "../app/constants/EmptyTemplates.js"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import HandleUsers from "../settings/HandleUsers.js"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import SpecialDropdown from "../settings/SpecialDropdown.js"
import Typography from "@mui/material/Typography"

const Settings = () => {
  const categories = [
    {singularTitle: "Wertpapierkategorie", pluralTitle:"Wertpapierkategorien", category:"Security Category"},
    {singularTitle: "Investmentkategorie", pluralTitle:"Investmentkategorien", category:"Investment Category"},
    {singularTitle: "Strategie", pluralTitle:"Strategien", category:"Strategy"},
    {singularTitle: "Empfehlungsquelle", pluralTitle:"Empfehlungsquellen", category:"Information Source"},
    {singularTitle: "Ordertyp", pluralTitle:"Ordertypen", category:"Order Type"},
    {singularTitle: "Wertpapierbörse", pluralTitle:"Wertpapierbörsen", category:"Exchange"}
  ]

  const [title, setTitle] = React.useState("")

  const { data: simpleChoices, isLoadingSimpleChoices } = api.useGetSimpleChoiceQuery()
  const [postSimpleChoice, postSimpleChoiceResult] = api.usePostSimpleChoiceMutation()
  const [deleteSimpleChoice, deleteSimpleChoiceResult] = api.useDeleteSimpleChoiceMutation()

  const [addSimpleChoice, setAddSimpleChoice] = React.useState(false)
  const [targetSimpleChoice, setTargetSimpleChoice] = React.useState(EmptyTemplates.emptySimpleChoice)

  const { data: currencies, isLoadingCurrencies } = api.useGetCurrencyQuery()
  const [postCurrency, postCurrencyResult] = api.usePostCurrencyMutation()
  const [deleteCurrency, deleteCurrencyResult] = api.useDeleteCurrencyMutation()

  const [addCurrency, setAddCurrency] = React.useState(false)
  const [targetCurrency, setTargetCurrency] = React.useState(EmptyTemplates.emptyCurrency)

  const { data: users, isLoadingUsers } = api.useGetUserQuery()
  const [postUser, postUserResult] = api.usePostUserMutation()
  const [deleteUser, deleteUserResult] = api.useDeactivateUserMutation()

  const [handleUsers, setHandleUsers] = React.useState(false)
  const [targetUser, setTargetUser] = React.useState(EmptyTemplates.emptyUser)

  const handleSimpleChoice = (target, category, title) => {
    setTargetSimpleChoice(target==null ? {...EmptyTemplates.emptySimpleChoice, category: category} : target)
    setTitle(title)
    setAddSimpleChoice(true)
  }
  const closeAddSimpleChoice = () => {
    setAddSimpleChoice(false)
  }
  const changeSimpleChoice = (value) => {
    setTargetSimpleChoice({...targetSimpleChoice, name: value})
  }
  const saveSimpleChoice = async () => {
    if(targetSimpleChoice.name!=""){
      setAddSimpleChoice(false)
      try{
        await postSimpleChoice(targetSimpleChoice)
      }catch{
      }
    }
  }
  const deleteSimpleChoiceFunc = async target => {
    try{
      await deleteSimpleChoice(target.id)
    }catch{
    }
  }

  const handleCurrency = (target) => {
    setTargetCurrency(target==null ? EmptyTemplates.emptyCurrency : target)
    setAddCurrency(true)
  }
  const closeAddCurrency = () => {
    setAddCurrency(false)
  }
  const changeCurrency = (newValue) => {
    setTargetCurrency({...targetCurrency, ...newValue})
  }
  const saveCurrency = async () => {
    if(targetCurrency.name!=""){
      setAddCurrency(false)
      try{
        await postCurrency(targetCurrency)
      }catch{
      }
    }
  }
  const deleteCurrencyFunc = async target => {
    try{
      await deleteCurrency(target.id)
    }catch{
    }
  }

  const openHandleUsers = () => {
    setHandleUsers(true)
  }
  const closeHandleUsers = () => {
    setHandleUsers(false)
  }
  const changeUser = (newValue) => {
    setTargetUser({...targetUser, newValue})
  }
  const saveUser = async () => {
    if(targetUser.username!=""){
      setHandleUsers(false)
      try{
        await postUser(targetUser)
      }catch{
      }
    }
  }
  const deleteUserFunc = async target => {
    try{
      await deleteUser(target.id)
    }catch{
    }
  }

  return (
    <Box>
      <Typography align="center" variant="body1" sx={{ fontSize: "30px", paddingBottom: 1}}>
        Einstellungen
      </Typography>
      <Container maxWidth="1000px" fixed>
        <Box sx={{padding: 2, maxHeight: "inherit"}}>
          <Box height="61vh" overflow="auto" sx={{marginBottom: 2}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Kategorien
                </Typography>

                <Grid container spacing={2} sx={{marginTop: 0}}>
                  {_.map(categories, (element) => {
                    return(
                      <SpecialDropdown
                        title={element.pluralTitle}
                        array={_.filter(simpleChoices, (choice) => { return choice.category == element.category })}
                        handleAdd={(target) => handleSimpleChoice(target, element.category, element.singularTitle)}
                        handleDelete={deleteSimpleChoiceFunc}
                      />
                    )
                  })}
                  <Grid item xs={12} md={6} container spacing={2}>
                    <Grid item xs={6} sx={{display: "flex", alignItems: "center"}}>
                      <Typography variant="h5">
                        Währungen
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl sx={{width: 200}}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={null}
                          onChange={(event) => (event.target.value=="Plus" ? handleCurrency(null) : null)}
                        >
                          {_.map(currencies, currency => {
                            return (
                              <MenuItem value={currency.name}>
                                <Typography sx={{marginRight: 1}}>{currency.name}</Typography>
                                <IconButton size="small" sx={{backgroundColor: "rgb(200,200,200)", marginRight: "0", marginLeft: "auto"}}>
                                  <EditIcon sx={{fontSize:"20px"}} onClick={() => handleCurrency(currency)}/>
                                </IconButton>
                                <IconButton size="small" sx={{backgroundColor: "rgb(200,200,200)", marginLeft: 1}} disabled={currency.numberDependencies>0}>
                                  <DeleteIcon sx={{fontSize:"20px"}} onClick={() => deleteCurrencyFunc(currency)}/>
                                </IconButton>
                              </MenuItem>
                            )
                          })}
                          <MenuItem value={"Plus"}>
                            <IconButton size="small" sx={{backgroundColor: "rgb(200,200,200)", marginRight: "auto", marginLeft: "auto"}}>
                              <AddIcon sx={{fontSize:"20px"}}/>
                            </IconButton>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h4">
                  Admin Einstellungen
                </Typography>
                <Grid container spacing={2} style={{marginTop:2}}>
                  <Grid item xs={6} sx={{display: "flex", alignItems: "center"}}>
                    <Typography variant="h5">
                      User Management
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton sx={{backgroundColor: "rgb(200,200,200)"}}>
                      <EditIcon onClick={() => openHandleUsers()}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <AddSimpleChoice
        title={title + (targetSimpleChoice.id=="" ? " aufzeichnen" : " ändern")}
        data={targetSimpleChoice}
        onSave={saveSimpleChoice}
        handleChange={changeSimpleChoice}
        open={addSimpleChoice}
        onClose={closeAddSimpleChoice}
      />
      <AddCurrency
        currency={targetCurrency}
        onSave={saveCurrency}
        handleChange={changeCurrency}
        open={addCurrency}
        onClose={closeAddCurrency}
      />
      <HandleUsers
        users={users}
        data={targetUser}
        onSave={saveUser}
        handleChange={changeUser}
        onClose={closeHandleUsers}
        open={handleUsers}
      />

    </Box>
  )
}

export default Settings
