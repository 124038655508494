import _ from "lodash"
import "moment/locale/de"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

const HandleTransaction = ({open, onClose, onSave, changeTransaction, transaction, orders, securities, order, currencies}) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{transaction.id=="" ? "Transaktion erfassen" : "Transaktion ändern"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} sx={{ paddingLeft: 2, paddingTop: 2 }}>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Order</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={transaction.stockOrderId}
                onChange={(event) => changeTransaction({ stockOrderId : event.target.value })}
                label="Order"
              >
                {_.map(orders, element => {
                  return(
                    <MenuItem value={element.id}>
                      {
                        element.type +
                        " von "
                        + (_.find(securities, (security) => { return security.id == element.securityId }) == null ? "" : _.find(securities, (security) => { return security.id == element.securityId }).name)
                      }
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DatePicker
              slotProps={{ textField: { fullWidth: true, required: true }}}
              defaultValue={transaction.transactionDate == "" ? null : new Date(transaction.transactionDate)}
              onChange={(value) => changeTransaction({ transactionDate : value })}
              label="Transaktionsdatum"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Kauftyp</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={transaction.type}
                onChange={(event) => changeTransaction({ type : event.target.value })}
                label="Kauftyp"
              >
                <MenuItem value={"Kauf"}>Kauf</MenuItem>
                <MenuItem value={"Verkauf"}>Verkauf</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label={"Anzahl" + (order.id == "" ? "" : ("(Maximal " + order.unfulfilled + ")")) }
              variant="outlined"
              value={transaction.number}
              onChange={(event) => changeTransaction({ number : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Kaufkurs"
              variant="outlined"
              value={transaction.price}
              onChange={(event) => changeTransaction({ price : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Gebühren"
              variant="outlined"
              value={transaction.fees}
              onChange={(event) => changeTransaction({ fees : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={
                transaction.currencyId == "" || transaction.currencyId == null ?
                null :
                _.find(currencies, (element) => { return element.id==transaction.currencyId}).name}
              onChange={(event, value, reason) => changeTransaction({ currencyId : (value != null ? _.find(currencies, (element) => { return element.name == value}).id : null) })}
              options={_.map(currencies, "name")}
              renderInput={(params) => <TextField required {...params} label="Währung" />}
            />
          </Grid>
          <Button variant="outlined" color="primary" component="label" >
            Dokument hochladen
            <input
              type="file"
              hidden
              onChange={(e) => {
                changeTransaction({ documentFile : e.target.files[0], documentName : e.target.files[0].name })
              }}
            />
          </Button>
        </Grid>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={onSave}
          disabled={
            transaction.stockOrderId=="" ||
            transaction.type=="" ||
            transaction.transactionDate=="" ||
            transaction.number=="" ||
            transaction.number==0 ||
            transaction.price=="" ||
            transaction.currencyId=="" ||
            transaction.fees==""
          }
        >
          Speichern
        </Button>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 4}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default HandleTransaction
