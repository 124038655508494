import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  me: null,
  error: null
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMe: (state, action) => {state.me = action.payload},
    setError: (state, action) => {state.error = action.payload},
    logout: (state) => {state = initialState}
  }
})

export default appSlice
export const sliceActions = appSlice.actions
