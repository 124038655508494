const BASE = process.env.REACT_APP_REST_BASE_URL

const base = () => BASE

const login = () => BASE + "/login"

const currency = () => BASE + "/currency"
const depot = () => BASE + "/depot"
const document = (id) => BASE + "/transaction/" + id + "/file"
const exchange = () => BASE + "/exchange"
const informationSource = () => BASE + "/informationSource"
const investmentCategory = () => BASE + "/investmentCategory"
const logout = () => BASE + '/logout'
const order = () => BASE + "/stockOrder"
const orderType = () => BASE + "/orderType"
const security = () => BASE + "/security"
const securityCategory = () => BASE + "/securityCategory"
const strategy = () => BASE + "/strategy"
const transaction = () => BASE + "/transaction"

export default ({
  base,

  login,

  currency,
  depot,
  document,
  exchange,
  informationSource,
  investmentCategory,
  logout,
  order,
  orderType,
  security,
  securityCategory,
  strategy,
  transaction
})
