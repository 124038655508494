import _ from "lodash"
import "moment/locale/de"
import { DialogContent } from "@mui/material"
import { useState } from "react"
import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

const CreateExitStrategy = ({open, onClose, onSave, changeExitStrategy, exitStrategy, exitStrategies, strategies, security}) => {

  const handleClose = () => {
    onClose()
  }
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>Verkaufsstrategie erfassen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{paddingLeft: 2, paddingTop: 3}}>
          <Grid item xs={12} sm={6} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={exitStrategy.strategyId == "" || exitStrategy.strategyId == null ? null : _.find(strategies, (element) => { return element.id==exitStrategy.strategyId}).name}
              onChange={(event, value, reason) => changeExitStrategy({ strategyId : (value != null ? _.find(strategies, (element) => { return element.name == value}).id : null)  })}
              options={_.map(strategies, "name")}
              renderInput={(params) => <TextField {...params} label="Wertpapierkategorie"/> }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Wert"
              variant="outlined"
              sx={{marginBottom: 2}}
              value={exitStrategy.argument}
              onChange={(event) => changeExitStrategy({ argument : event.target.value })}
            />
          </Grid>
        </Grid>
        <Button variant="contained" sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}} onClick={onSave}>Speichern</Button>

        <Grid container spacing={2} sx={{paddingLeft: 2, paddingTop: 3}}>
            {_.map(exitStrategies, element =>{
              return (
                <Grid item xs={12} sm={12} lg={12}>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    Verkaufsstrategie: {
                      _.find(strategies, (strategy) => { return strategy.id == element.strategyId }) == null
                      ? null : _.find(strategies, (strategy) => { return strategy.id == element.strategyId }).name
                    }
                  </Typography>
                </Grid>
              )
            })}
        </Grid>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default CreateExitStrategy