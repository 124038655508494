import { configureStore } from "@reduxjs/toolkit"
import { Provider } from "react-redux"
import { setupListeners } from "@reduxjs/toolkit/query/react"
import api from "./app/api"
import App from "./App"
import React from "react"
import ReactDOM from "react-dom"
import slice from "./app/slice"

export const store = configureStore({
  reducer: {
    [slice.name]: slice.reducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})

setupListeners(store.dispatch)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)