import _ from "lodash"
import "moment/locale/de"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

const HandleOrder = ({ onClose, open, onSave, changeOrder, order, securities, orderTypes, exchanges, currencies }) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{order.id=="" ? "Order erfassen" : "Order ändern"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} sx={{ paddingLeft: 2, paddingTop: 2 }}>
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Kauftyp</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={order.type}
                onChange={(event) => changeOrder({ type : event.target.value })}
                label="Kauftyp"
              >
                <MenuItem value={"Kauf"}>Kauf</MenuItem>
                <MenuItem value={"Verkauf"}>Verkauf</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={
                order.orderTypeId == "" || order.orderTypeId == null ?
                null :
                _.find(orderTypes, (element) => { return element.id==order.orderTypeId }).name}
              onChange={(event, value, reason) => changeOrder({ orderTypeId : (value != null ? _.find(orderTypes, (element) => { return element.name == value}).id : null) })}
              options={_.map(orderTypes, "name")}
              renderInput={(params) => <TextField required {...params} label="Ordertyp" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={
                order.exchangeId == "" || order.exchangeId == null ?
                null :
                _.find(exchanges, (element) => { return element.id==order.exchangeId}).name}
              onChange={(event, value, reason) => changeOrder({ exchangeId : (value != null ? _.find(exchanges, (element) => { return element.name == value}).id : null) })}
              options={_.map(exchanges, "name")}
              renderInput={(params) => <TextField required {...params} label="Börse" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DatePicker
              slotProps={{ textField: { fullWidth: true, required: true }}}
              defaultValue={order.expirationDate == "" ? null : new Date(order.expirationDate)}
              onChange={(value) => changeOrder({ expirationDate : value })}
              label="Verfallsdatum"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={
                order.securityId == "" || order.securityId == null ?
                null :
                _.find(securities, (element) => { return element.id==order.securityId}).name}
              onChange={(event, value, reason) => changeOrder({ securityId : (value != null ? _.find(securities, (element) => { return element.name == value}).id : null) })}
              options={_.map(securities, "name")}
              renderInput={(params) => <TextField required {...params} label="Wertpapier" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Anzahl"
              variant="outlined"
              value={order.number}
              onChange={(event) => changeOrder({ number : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Kaufkurs"
              variant="outlined"
              value={order.price}
              onChange={(event) => changeOrder({ price : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={
                order.currencyId == "" || order.currencyId == null ?
                null :
                _.find(currencies, (element) => { return element.id==order.currencyId}).name
              }
              onChange={(event, value, reason) => changeOrder({ currencyId : (value != null ? _.find(currencies, (element) => { return element.name == value}).id : null) })}
              options={_.map(currencies, "name")}
              renderInput={(params) => <TextField required {...params} label="Währung"/>}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={9}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Begründung"
              variant="outlined"
              value={order.justification}
              onChange={(event) => changeOrder({ justification : event.target.value })}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={onSave}
          disabled={
            order.securityId=="" ||
            order.type=="" ||
            order.orderTypeId=="" ||
            order.exchangeId=="" ||
            order.number=="" ||
            order.price=="" ||
            order.currencyId=="" ||
            order.expirationDate==""
          }
        >
          Speichern
        </Button>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 4}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default HandleOrder
