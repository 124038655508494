import _ from "lodash"
import "moment/locale/de"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const LookSecurity = ({open, onClose, security}) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{"Wertpapier: " + security.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              <b>WKN / ISIN / US-Kürzel:</b> {security.code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              <b>Wertpapierkategorie:</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              <b>Investmentkategorie:</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              <b>Empfehlungsquelle:</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              <b>Notizen:</b> {security.notizen}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Grid container spacing={2} sx={{marginTop:-2.75}}>
              <Grid item sx={{display: "flex", alignItems: "center"}}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Depot:</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} sx={{marginTop: -1.2}}>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              <b>Verkaufsstrategie:</b>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default LookSecurity
