import _ from "lodash"
import "moment/locale/de"
import * as React from "react"
import AddIcon from "@mui/icons-material/Add"
import api from "../app/api.js"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import EmptyTemplates from "../app/constants/EmptyTemplates.js"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import HandleTransaction from "../order/HandleTransaction.js"
import LookDocument from "../transaction/LookDocument.js"
import moment from "moment"
import TextField from "@mui/material/TextField"
import TransactionCard from "../transaction/TransactionCard.js"
import Typography from "@mui/material/Typography"

const Transaction = () => {

  const [targetOrder, setTargetOrder] = React.useState(EmptyTemplates.emptyOrder)

  const [handleTransaction, setHandleTransaction] = React.useState(false)
  const [targetTransaction, setTargetTransaction] = React.useState(EmptyTemplates.emptyTransaction)
  const [previewTransaction, setPreviewTransaction] = React.useState(null)

  const [lookDocument, setLookDocument] = React.useState(false)

  const { data: securities, isLoadingSecurities } = api.useGetSecurityQuery()

  const { data: orders, isLoadingOrders } = api.useGetOrderQuery()

  const { data: transactions, isLoadingTransactions } = api.useGetTransactionQuery()
  const [postTransaction, postTransactionResult] = api.usePostTransactionMutation()
  const [deleteTransaction, deleteTransactionResult] = api.useDeleteTransactionMutation()

  const { data: simpleChoices, isLoadingSimpleChoices } = api.useGetSimpleChoiceQuery()
  const { data: currencies, isLoadingCurrencies } = api.useGetCurrencyQuery()

  const [filterTerm, setFilterTerm] = React.useState("")
  const [strictSearch, setStrictSearch] = React.useState(false)

  const stillLoading = () => {
    if(!isLoadingTransactions){
    }
    return isLoadingSecurities || isLoadingOrders || isLoadingTransactions || isLoadingSimpleChoices || isLoadingCurrencies
  }

  const filterFunc = (element) => {
    if(filterTerm == ""){
      return true
    }
    let remains = false
    _.map(_.entries(element), word => {
      if(remains==false && word[0]!="id" && word[1]!=null){
        if(word[0]=="securityId"){
          const order = _.find(orders, (element) => { return element.id==word[1]})
          const securityWord = _.find(securities, (element) => { return element.id==order.securityId}).name
          remains = strictSearch ? (securityWord.toString().toLowerCase()).substring(0,filterTerm.length)==filterTerm.toLowerCase() : (securityWord.toString().toLowerCase()).includes(filterTerm.toLowerCase())
        }else{
          remains = strictSearch ? (word[1].toString().toLowerCase()).substring(0,filterTerm.length)==filterTerm.toLowerCase() : (word[1].toString().toLowerCase()).includes(filterTerm.toLowerCase())
        }
      }
    })
    return remains
  }

  const saveTransaction = async () => {
    if(
      targetTransaction.stockOrderId!="" &&
      targetTransaction.type!="" &&
      targetTransaction.transactionDate!="" &&
      targetTransaction.number!="" &&
      targetTransaction.price!="" &&
      targetTransaction.currencyId!="" &&
      targetTransaction.fees!=""
    ){
      setHandleTransaction(false)
      try{
        const formData = new FormData()
        _.forOwn({...targetTransaction, transactionDate: moment(targetTransaction.transactionDate)}, (value, key) => formData.set(key, value))
        await postTransaction(formData)
      }catch{
      }
    }
  }
  const changeTransaction = (newValue) => {
    setTargetTransaction({...targetTransaction, ...newValue})
    setTargetOrder(
      _.find(orders, (order) => { return order.id == targetTransaction.stockOrderId }) == null
      ?
      EmptyTemplates.emptyOrder
      :
      _.find(orders, (order) => { return order.id == targetTransaction.stockOrderId })
    )
  }
  const deleteTransactionFunc = async (target) => {
    try{
      await deleteTransaction(target.id)
    }catch{
    }
  }

  const openHandleTransaction = (target) => {
    setTargetTransaction(target)
    setHandleTransaction(true)
  }
  const closeHandleTransaction = () => {
    setHandleTransaction(false)
    setTargetTransaction(EmptyTemplates.emptyTransaction)
  }

  const openLookDocument = (target) => {
    setPreviewTransaction(target)
    setLookDocument(true)
  }
  const closeLookDocument = () => {
    setPreviewTransaction(null)
    setLookDocument(false)
  }

  return (
    <Box>
      <Typography align="center" variant="body1" sx={{ fontSize: "30px", paddingBottom: 1}}>
        Transaktionen
      </Typography>
      <Container maxWidth="1000px" fixed>
        <Box>
          <TextField
            id="outlined-basic"
            label="Suche..."
            variant="outlined"
            onChange={(event) =>
            setFilterTerm(event.target.value)}
          />
          <FormControlLabel
            control={<Checkbox/>}
            label="Strenge Suche"
            sx={{paddingTop: 1, paddingLeft: 2}}
            checked={strictSearch}
            onChange={() => setStrictSearch(!strictSearch)}
          />
          <Box sx={{height: "68vh", paddingLeft: 1, paddingTop: 3, overflow: "auto"}}>
            <Grid container spacing={2}>
              {stillLoading() ? null : _.map(_.filter(transactions, (unfiltered) => filterFunc(unfiltered)), transaction =>{
              const order = _.find(orders, (element) => { return element.id==transaction.stockOrderId})
              const security = order == null ? null : _.find(securities, (element) => { return element.id==order.securityId})
              const currency = order == null ? null : _.find(currencies, (element) => { return element.id==order.currencyId})
                return (
                  <Grid item xs={12} lg={6}>
                    <TransactionCard
                      transaction={transaction}
                      order={order == null ? EmptyTemplates.emptyOrder : order}
                      security={security == null ? EmptyTemplates.emptySecurity : security}
                      currency={currency == null ? EmptyTemplates.emptyCurrency : currency}
                      modify={value => openHandleTransaction(value)}
                      deleteTransactionFunc={value => deleteTransactionFunc(value)}
                      openLookDocument={(target) => openLookDocument(target)}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Button
            variant="contained"
            sx={{ height: 40, width: 40, minWidth: 40, float: "right"}}
            onClick={() => openHandleTransaction(EmptyTemplates.emptyTransaction)}
          >
            <AddIcon />
          </Button>
        </Box>
        <HandleTransaction
          open={handleTransaction}
          onClose={closeHandleTransaction}
          onSave={saveTransaction}
          changeTransaction={changeTransaction}
          transaction={targetTransaction}
          orders={orders}
          securities={securities == null ? EmptyTemplates.emptySecurity : securities}
          order={targetOrder}
          currencies={currencies}
        />
        {previewTransaction == null ? null :
          <LookDocument
            open={lookDocument}
            onClose={closeLookDocument}
            transaction={previewTransaction}
          />
        }
      </Container>
    </Box>
  )
}

export default Transaction
