const emptyDepot = {
  id: "",
  bank: "",
  accountNumber: "",
  currencyId: "",
  salutation: "",
  name: "",
  surname: "",
  street: "",
  postalCode: "",
  city: ""
}

const emptySecurity = {
  id: "",
  code: "",
  name: "",
  securityCategoryId: "",
  investmentCategoryId: "",
  informationSourceId: "",
  notizen: "",
  depotId: ""
}

const emptyOrder = {
  id: "",
  securityId: "",
  type: "",
  orderTypeId: "",
  justification: "",
  expirationDate: "",
  number: "",
  price: "",
  fees: "",
  currencyId: ""
}

const emptyTransaction = {
  id: "",
  stockOrderId: "",
  type: "",
  transactionDate: "",
  number: "",
  price: "",
  fees: "",
  currencyId: "",
  documentName: ""
}

const emptyCurrency = {
  id: "",
  code: "",
  name: "",
  symbol: ""
}

const emptySimpleChoice = {
  id: "",
  name: "",
  category: ""
}

const emptyExitStrategy = {
  id: "",
  strategyId: "",
  securityId: "",
  argument1: "",
  argument2: ""
}

const emptyUser = {
  id: "",
  username: "",
  firstName: "",
  secondName: "",
  email: ""
}

export default ({
  emptyDepot,
  emptySecurity,
  emptyOrder,
  emptyTransaction,
  emptyCurrency,
  emptySimpleChoice,
  emptyExitStrategy,
  emptyUser
})
