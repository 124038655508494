
import _ from "lodash"
import "moment/locale/de"
import * as React from "react"
import AddIcon from "@mui/icons-material/Add"
import api from "../app/api.js"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import CreateExitStrategy from "../security/CreateExitStrategy.js"
import EmptyTemplates from "../app/constants/EmptyTemplates.js"
import FormControlLabel from "@mui/material/FormControlLabel"
import HandleSecurity from "../security/HandleSecurity.js"
import LookDepot from "../security/LookDepot.js"
import SecurityCard from "../security/SecurityCard.js"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const Security = () => {

  const [handleSecurity, setHandleSecurity] = React.useState(false)
  const [lookDepot, setLookDepot] = React.useState(false)
  const [handleExitStrategy, setHandleExitStrategy] = React.useState(false)

  const { data: depots, isLoadingDepots } = api.useGetDepotQuery()
  const { data: securities, isLoadingSecurities } = api.useGetSecurityQuery()
  const [postSecurity, postSecurityResult] = api.usePostSecurityMutation()
  const [deleteSecurity, deleteSecurityResult] = api.useDeleteSecurityMutation()

  const { data: exitStrategies, isLoadingExitStrategies } = api.useGetExitStrategyQuery()
  const [postExitStrategy, postExitStrategyResult] = api.usePostExitStrategyMutation()
  const [deleteExitStrategy, deleteExitStrategyResult] = api.useDeleteExitStrategyMutation()

  const { data: simpleChoices, isLoadingSimpleChoices } = api.useGetSimpleChoiceQuery()

  const [targetSecurity, setTargetSecurity] = React.useState(EmptyTemplates.emptySecurity)
  const [targetDepot, setTargetDepot] = React.useState(EmptyTemplates.emptyDepot)
  const [targetExitStrategy, setTargetExitStrategy] = React.useState(EmptyTemplates.emptyExitStrategy)

  const [filterTerm, setFilterTerm] = React.useState("")
  const [strictSearch, setStrictSearch] = React.useState(false)

  const stillLoading = () => {
    return isLoadingDepots || isLoadingSecurities || isLoadingSimpleChoices || isLoadingExitStrategies
  }

  const filterFunc = (element) => {
    if(filterTerm == ""){
      return true
    }
    let remains = false
    _.map(_.entries(element), word => {
      if(remains==false && word[0]!="id" && word[1]!=null){
        remains = strictSearch ? (word[1].toString().toLowerCase()).substring(0,filterTerm.length)==filterTerm.toLowerCase() : (word[1].toString().toLowerCase()).includes(filterTerm.toLowerCase())
      }
    })
    return remains
  }

  const saveSecurity = async () => {
    if(targetSecurity.code!="" && targetSecurity.name!=""){
      setHandleSecurity(false)
      try{
        await postSecurity(targetSecurity)
      }catch{
      }
    }
  }
  const changeSecurity = (newValue) => {
    setTargetSecurity({...targetSecurity, ...newValue})
  }
  const deleteSecurityFunc = async (target) => {
    try{
      await deleteSecurity(target.id)
    }catch{
    }
  }
  const openHandleSecurity = (target) => {
    setTargetSecurity(target)
    setHandleSecurity(true)
  }
  const closeHandleSecurity = () => {
    setHandleSecurity(false)
  }

  const openLookDepot = (depot) => {
    setTargetDepot(depot)
    setLookDepot(true)
  }
  const closeLookDepot = () => {
    setLookDepot(false)
    setTargetDepot(EmptyTemplates.emptyDepot)
  }

  const saveExitStrategy = async () => {
    if(targetExitStrategy.strategyId!=""){
      try{
        await postExitStrategy(targetExitStrategy)
        setHandleExitStrategy(false)
      }catch{
      }
    }
  }
  const changeExitStrategy = (newValue) => {
    setTargetExitStrategy({...targetExitStrategy, ...newValue})
  }
  const openHandleExitStrategy = (target) => {
    setTargetSecurity(target)
    setTargetExitStrategy({...EmptyTemplates.emptyExitStrategy, securityId: target.id})
    setHandleExitStrategy(true)
  }
  const closeHandleExitStrategy = () => {
    setHandleExitStrategy(false)
  }

  return (
    <Box>
      <Typography align="center" variant="body1" sx={{ fontSize: "30px", paddingBottom: 1}}>
        Wertpapiere
      </Typography>
      <Container maxWidth="1000px" fixed>
        <Box>
          <TextField
            id="outlined-basic"
            label="Suche..."
            variant="outlined"
            onChange={(event) =>
            setFilterTerm(event.target.value)}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Strenge Suche"
            sx={{paddingTop: 1, paddingLeft: 2}}
            checked={strictSearch}
            onChange={() => setStrictSearch(!strictSearch)}
          />
          <Box sx={{height: "68vh", paddingLeft: 1, paddingTop: 3, overflow: "auto"}}>
            <Grid container spacing={2}>
              {stillLoading() ? null : _.map(_.filter(securities, (element) => filterFunc(element)), security =>{
                const securityCategory = _.find(simpleChoices, (choice) => { return choice.id == security.securityCategoryId})
                const investmentCategory = _.find(simpleChoices, (choice) => { return choice.id == security.investmentCategoryId})
                const informationSource = _.find(simpleChoices, (choice) => { return choice.id == security.informationSourceId})
                const depot = _.find(depots, (element) => { return element.id==security.depotId})
                return (
                  <Grid item xs={12} lg={6}>
                    <SecurityCard
                      deleteSecurityFunc={value => deleteSecurityFunc(value)}
                      depot={depot != null ? depot : EmptyTemplates.emptyDepot}
                      informationSource={informationSource == null ? null : informationSource.name}
                      investmentCategory={investmentCategory == null ? null : investmentCategory.name}
                      modify={value => openHandleSecurity(value)}
                      openHandleExitStrategy={openHandleExitStrategy}
                      openLookDepot={depot => openLookDepot(depot)}
                      security={security}
                      securityCategory={securityCategory == null ? null : securityCategory.name}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Button
            variant="contained"
            sx={{ height: 40, width: 40, minWidth: 40, float: "right"}}
            onClick={() => openHandleSecurity(EmptyTemplates.emptySecurity)}
          >
            <AddIcon/>
          </Button>
        </Box>
        <HandleSecurity
          changeSecurity={changeSecurity}
          depots={depots}
          informationSources={_.filter(simpleChoices, (choice) => { return choice.category == "Information Source" })}
          investmentCategories={_.filter(simpleChoices, (choice) => { return choice.category == "Investment Category" })}
          onClose={closeHandleSecurity}
          onSave={saveSecurity}
          open={handleSecurity}
          security={targetSecurity}
          securityCategories={_.filter(simpleChoices, (choice) => { return choice.category == "Security Category" })}
        />
        <CreateExitStrategy
          changeExitStrategy={changeExitStrategy}
          exitStrategies={_.filter(exitStrategies, (element) => { return element.securityId == targetSecurity.id })}
          exitStrategy={targetExitStrategy}
          onClose={closeHandleExitStrategy}
          onSave={saveExitStrategy}
          open={handleExitStrategy}
          security={targetSecurity}
          strategies={_.filter(simpleChoices, (choice) => { return choice.category == "Strategy" })}
        />
        <LookDepot
          depot={targetDepot}
          onClose={closeLookDepot}
          open={lookDepot}
        />
      </Container>
    </Box>
  )
}

export default Security
