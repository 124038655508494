import _ from "lodash"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

const HandleUsers = ({users, data, onSave, handleChange, onClose, open}) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>Users</DialogTitle>
      <DialogContent>
        <Box height="61vh" overflow="auto" sx={{ marginBottom: 2 }}>
          <Grid container spacing={4} sx={{paddingLeft: 2, paddingTop: 2}}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Username"
                variant="outlined"
                value={data.username}
                onChange={(event) => handleChange({ username : event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Vorname"
                variant="outlined"
                value={data.firstName}
                onChange={(event) => handleChange({ firstName : event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Nachname"
                variant="outlined"
                value={data.secondName}
                onChange={(event) => handleChange({ secondName : event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={data.email}
                onChange={(event) => handleChange({ email : event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.role}
                  onChange={(event) => handleChange({ role : event.target.value })}
                  label="Role"
                >
                  <MenuItem value={"normal"}>Normale User</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box height="61vh" overflow="auto" sx={{ marginBottom: 2 }}>
          {
            _.map(users, user =>{

            })
          }
        </Box>
        <Button variant="contained" sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}} onClick={onSave}>
          Speichern
        </Button>
        <Button variant="contained" sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}} onClick={handleClose}>
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default HandleUsers
