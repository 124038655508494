import _ from "lodash"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

const AddSimpleChoice = ({title, data, onSave, handleChange, onClose, open}) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} sx={{paddingLeft: 2, paddingTop: 2}}>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={data.name}
              onChange={(event) => handleChange(event.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={onSave}
        >
          Speichern
        </Button>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 4}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default AddSimpleChoice
