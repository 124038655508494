import { Button, Card, CardContent, CardActions } from "@mui/material"
import { useNavigate } from "react-router-dom"

const LoginError = () => {
  const navigate = useNavigate()

  return (
    <Card style={{ backgroundColor: "red", marginTop: "calc(100vh/3)", maxWidth: "1200px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
      <CardContent>
        Sie sind für die Software "transera" aktuell nicht freigegeben. <br/>
        Kontaktieren Sie Ihren Administrator oder unseren Support, falls Sie eine Freigabe wünschen.
      </CardContent>
      <CardActions style={{ float: "right" }}>
        <Button onClick={() => navigate("/logout")} style={{ color: "black" }}>Logout</Button>
      </CardActions>
    </Card>
  )
}

export default LoginError
