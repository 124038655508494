import _ from "lodash"
import "moment/locale/de"
import { DialogContent } from "@mui/material"
import { useState, useEffect } from "react"
import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

const HandleDepot = ({ onClose, open, onSave, changeDepot, depot, currencies }) => {
  const [width, setWidth] = useState((typeof window === "undefined") ? null : window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWidth((typeof window === "undefined") ? null : window.innerWidth)
    }
    if(typeof window === "undefined"){
      window.addEventListener("resize", handleResize)
    }
    return () => ((typeof window === "undefined") ? null : window.removeEventListener("resize", handleResize))
  }, [width])

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>{depot.id=="" ? "Depot aufzeichnen" : "Depot ändern"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} sx={{paddingLeft: 2, paddingTop: 2}}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Name der Bank"
              variant="outlined"
              value={depot.bank}
              onChange={(event) => changeDepot({ bank : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Depotnummer"
              variant="outlined"
              value={depot.accountNumber}
              onChange={(event) => changeDepot({ accountNumber : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              onChange={(event, value, reason) => changeDepot({ currencyId : (value != null ? _.find(currencies, (element) => { return element.name == value}).id : null) })}
              options={_.map(currencies, "name")}
              renderInput={(params) => <TextField required {...params} label="Währung"/>}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={{marginBottom: -2}}>
              Depotinhaber
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Anrede</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={depot.salutation}
                  onChange={(event) => changeDepot({ salutation : event.target.value })}
                  label="Anrede"
                >
                  <MenuItem value="Frau">Frau</MenuItem>
                  <MenuItem value="Mann">Mann</MenuItem>
                  <MenuItem value="">Keine Angabe</MenuItem>
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Vorname"
              variant="outlined"
              value={depot.name}
              onChange={(event) => changeDepot({ name : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={depot.surname}
              onChange={(event) => changeDepot({ surname : event.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Straße HNR"
              variant="outlined"
              value={depot.street}
              onChange={(event) => changeDepot({ street : event.target.value })}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="PLZ"
              variant="outlined"
              value={depot.postalCode}
              onChange={(event) => changeDepot({ postalCode : event.target.value })}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <TextField
              fullWidth
              required
              id="outlined-basic"
              label="Ort"
              variant="outlined"
              value={depot.city}
              onChange={(event) => changeDepot({ city : event.target.value })}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained" sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 3}}
          onClick={onSave}
          disabled={
            depot.bank=="" ||
            depot.accountNumber=="" ||
            depot.salutation=="" ||
            depot.name=="" ||
            depot.surname=="" ||
            depot.street=="" ||
            depot.postalCode=="" ||
            depot.city==""
          }
        >
          Speichern
        </Button>
        <Button
          variant="contained"
          sx={{height: 40, width: 120, minWidth: 40, marginTop: 2, marginLeft: 4}}
          onClick={handleClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default HandleDepot
