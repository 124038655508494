import _ from "lodash"
import * as React from "react"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"

const SpecialDropdown = ({title, array, handleAdd, handleDelete}) => {

  return (
    <Grid item xs={12} md={6} container spacing={2}>
      <Grid item xs={6} sx={{display: "flex", alignItems: "center"}}>
        <Typography variant="h5">
          {title}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <FormControl sx={{width: 200}}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={null}
            onChange={(event) => (event.target.value=="Plus" ? handleAdd(null) : null)}
          >
            {_.map(array, element => {
              return (
                <MenuItem value={element.name}>
                  <Typography sx={{marginRight: 1}}>{element.name}</Typography>
                  <IconButton size="small" sx={{backgroundColor: "rgb(200,200,200)", marginRight: "0", marginLeft: "auto"}}>
                    <EditIcon sx={{fontSize:"20px"}} onClick={() => handleAdd(element)}/>
                  </IconButton>
                  <IconButton size="small" sx={{backgroundColor: "rgb(200,200,200)", marginLeft: 1}} disabled={element.numberDependencies>0}>
                    <DeleteIcon sx={{fontSize:"20px"}} onClick={() => handleDelete(element)}/>
                  </IconButton>
                </MenuItem>
              )
            })}
            <MenuItem value={"Plus"}>
              <IconButton size="small" sx={{backgroundColor: "rgb(200,200,200)", marginRight: "auto", marginLeft: "auto"}}>
                <AddIcon sx={{fontSize:"20px"}}/>
              </IconButton>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default SpecialDropdown
