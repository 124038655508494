import _ from "lodash"
import "moment/locale/de"
import { styled } from "@mui/material/styles"
import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Collapse from "@mui/material/Collapse"
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import moment from "moment"
import Typography from "@mui/material/Typography"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

const TransactionCard = ({transaction, order, security, currency, modify, deleteTransactionFunc, openLookDocument}) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const lookDocumentButton = (event) => {
    event.stopPropagation()
    openLookDocument(transaction)
  }

  return (
    <Card sx={{ marginBottom: 2, backgroundColor: "rgb(240,240,240)" }}>
      <CardHeader
        action={
          <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={order.type + "stransaktion von " + security.name}
        subheader={"Verfallsdatum: " + moment(transaction.transactionDate).locale("de").format("D. MMMM YYYY")}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }} onClick={handleExpandClick}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Order:</b> {order.type + "sorder von "  + security.name + "(Nummer:" + order.id + ") "}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Anzahl:</b> {transaction.number}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Kaufkurs:</b> {transaction.price}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Gebühren:</b> {transaction.fees}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Währung:</b> {currency == null ? null : currency.name}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Dokument:</b> {transaction.documentName == "" ? "Kein Dokument" : transaction.documentName}
              </Typography>
              {transaction.documentName != "" ?
                <Grid item sx={{display: "flex", alignItems: "center"}}>
                  <IconButton sx={{backgroundColor: "rgb(204, 204, 204)"}} onClick={(event) => lookDocumentButton(event)}>
                    <ContentPasteSearchIcon/>
                  </IconButton>
                </Grid>
                : null
              }
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{marginTop: 1}}>
          <IconButton sx={{ backgroundColor: "rgb(200,200,200)" }} onClick={() => modify(transaction)}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ backgroundColor: "rgb(200,200,200)" }} onClick={() => deleteTransactionFunc(transaction)} disabled={transaction.numberDependencies>0}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Collapse>
    </Card>
  )
}

export default TransactionCard
