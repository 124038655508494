import _ from "lodash"
import "moment/locale/de"
import { DialogContent } from "@mui/material"
import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const LookDepot = ({onClose, depot, open}) => {

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xl">
      <DialogTitle>Security</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Name der Bank:</b> {depot.bank}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                <b>Depotnummer:</b> {depot.accountNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{marginBottom: 1}}>
              Depotinhaber
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Anrede:</b> {depot.salutation}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Vorname:</b> {depot.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Name:</b> {depot.surname}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Straße HNR:</b> {depot.street}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>PLZ:</b> {depot.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <b>Ort:</b> {depot.city}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default LookDepot
